import * as moment from 'moment';
import { LocalStorageService } from './../utils/local-storage.service';
import constant from './../app.constant';

export class CommonLogicService {
    public localData: any = new LocalStorageService();

    public getUtcOffset() {
        return new Date().getTimezoneOffset() * -1;
    }
    public getCurrentDate() {
        return moment(new Date()).format(constant.DateFormatServerSideWithDateTime);
    }
    // public convertLocalToUtcTime(date: any) {
    //     var utcTime = moment.utc(date, constant.dateTimeFormat).utc().format(constant.DateFormatServerSideWithDateTime)
    //     return utcTime
    // }
    public givenDateTimeAndOffsetToChangeUtcTimeFrontendTobackend(dateTime: any, dateFormat: any) {
        let reqUtcOffset: any;
        let dateTimeFormat: any = constant.dateTimeFormat;
        if (this.localData.getItem(constant.localStorage.utc_offset)) {
            reqUtcOffset = -1 * Number(this.localData.getItem(constant.localStorage.utc_offset))
        } else {
            reqUtcOffset = -1 * this.getUtcOffset();
        }
        if (dateFormat) dateTimeFormat = dateFormat;
        var utcTime = moment.utc(dateTime, dateTimeFormat).utcOffset(reqUtcOffset).format(constant.DateFormatServerSideWithDateTime);

        return utcTime;
    }

    public givenDateTimeAndOffsetToChangeUtcTimebackendToFrontend(dateTime: any, dateFormat: any) {
        let reqUtcOffset: any;
        let dateTimeFormat: any = constant.dateTimeFormat;
        if (this.localData.getItem(constant.localStorage.utc_offset)) {
            reqUtcOffset = Number(this.localData.getItem(constant.localStorage.utc_offset))
        } else {
            reqUtcOffset = -1 * this.getUtcOffset();
        }
        if (dateFormat) dateTimeFormat = dateFormat;
        var utcTime = moment.utc(dateTime, dateTimeFormat).utcOffset(reqUtcOffset).format(constant.DateFormatServerSideWithDateTime);

        return utcTime;
    }
    // public currentUtcToReqTimeConversion(date: any) {
    //     var utcOffset = this.getUtcOffset();
    //     return moment.utc(this.utcDateTime(), constant.DateFormatServerSideWithDateTime).utcOffset(Number(utcOffset)).format(constant.DateFormatServerSideWithDateTime);
    // }
    // public utcDateTime() {
    //     return new Date().toISOString().replace(/T/, ' ').replace(/\..+/, '');
    // }
    public isObject(value: any) {
        return typeof value === 'object';
    }
    public dateFormaterStringToJson(date: any) {
        var reqJson: any = {}
        reqJson.day = parseInt(moment(date, 'YYYY-MM-DD').format('DD'));
        reqJson.month = parseInt(moment(date, 'YYYY-MM-DD').format('MM'));
        reqJson.year = parseInt(moment(date, 'YYYY-MM-DD').format('YYYY'));
        return reqJson;
    }
    public convertUTCDateToLocalDate(date: any) {
        var local = moment.utc(date, constant.DateFormatServerSideWithDateTime).local().format(constant.DateFormatServerSideWithDateTime);
        return local;
    }
    public givenDateTimeAndOffsetToChangeUtcTimeEvent(dateTime: any, givenFormat?: any, resultFormat?: any) {
        givenFormat = givenFormat ? givenFormat : constant.DateFormatServerSideWithDateTime;
        resultFormat = resultFormat ? resultFormat : constant.DateFormatServerSideWithDateTime;
        var reqUtcOffset = -1 * this.getUtcOffset();
        var utcTime = moment.utc(dateTime, givenFormat).utcOffset(reqUtcOffset).format(resultFormat);
        return utcTime;
    }
    public convertUTCDateToLocalDateTime(date) {
        var local = moment.utc(date).local().format('LT');
        return local;
    }
    public uTCDateToLocalDateTimeConvert(date: any) {
        var local = moment.utc(date).local().format('lll');
        return local;
    };

    public differentiateDateTag(utcDateTime: string): string {
        // Convert UTC date-time to local date-time
        const localDateTime = moment.utc(utcDateTime).local();

        // Get the current local date-time
        const currentLocalDateTime = moment();

        // Check if the local date-time is valid
        if (!localDateTime.isValid()) {
            return 'Invalid date';
        }

        // Calculate the difference in milliseconds
        const diffMilliseconds = currentLocalDateTime.diff(localDateTime);

        // Calculate the difference in days, hours, and minutes
        const diffDuration = moment.duration(diffMilliseconds);
        const diffDays = diffDuration.days();
        const diffHours = diffDuration.hours();
        const diffMinutes = diffDuration.minutes();

        // Format the result based on the difference
        if (diffDays > 0) {
            // Return in format "X days, Y hours, Z minutes"
            return `${diffDays} Day${diffDays > 1 ? 's' : ''}, ${diffHours} Hour${diffHours > 1 ? 's' : ''}, ${diffMinutes} Min${diffMinutes > 1 ? 's' : ''}`;
        } else if (diffHours > 0) {
            // Return in format "Y hours, Z minutes"
            return `${diffHours} Hour${diffHours > 1 ? 's' : ''}, ${diffMinutes} Min${diffMinutes > 1 ? 's' : ''}`;
        } else {
            // Return in format "Z minutes"
            return `${diffMinutes} Min${diffMinutes > 1 ? 's' : ''}`;
        }
    }
    public convertUTCTimeToLocalTimeFrontendTobackend(time: any) {
        var dateTime = moment().format('YYYY-MM-DD') + time;
        let reqUtcOffset: any;
        if (this.localData.getItem(constant.localStorage.utc_offset)) {
            reqUtcOffset = -1 * Number(this.localData.getItem(constant.localStorage.utc_offset))
        } else {
            reqUtcOffset = -1 * this.getUtcOffset();
        }
        var localString = moment.utc(dateTime, constant.DateFormatServerSideWithDateTime).utcOffset(reqUtcOffset).format(constant.DateFormatServerSideWithDateTime);
        // var localString = moment.utc(dateTime, constant.DateFormatServerSideWithDateTime).local().format(constant.DateFormatServerSideWithDateTime);
        const date = moment(localString, 'YYYY-MM-DD HH:mm');
        const local = date.format('HH:mm');
        return local;
    }
    public convertToLocalToUtcTimebackendToFrontend(time: any) {
        var dateTime = moment().format('YYYY-MM-DD') + time;
        let reqUtcOffset: any;
        if (this.localData.getItem(constant.localStorage.utc_offset)) {
            reqUtcOffset = Number(this.localData.getItem(constant.localStorage.utc_offset))
        } else {
            reqUtcOffset = -1 * this.getUtcOffset();
        }
        var localString = moment.utc(dateTime, constant.DateFormatServerSideWithDateTime).utcOffset(reqUtcOffset).format(constant.DateFormatServerSideWithDateTime);
        const date = moment(localString, 'YYYY-MM-DD HH:mm');
        const utcTime = date.format('HH:mm');
        return utcTime;
    }
    public convertLocalDate(date: any) {
        var local = moment(date, constant.DateFormatServerSideWithDateTime).format(constant.DateFormatServerSideDate);
        return local;
    }

    public convertToServeDate(date: any) {
        var serveDate = moment(date, constant.DateFormatHtml).format(constant.DateFormatServerSideDate);
        return serveDate;
    }
    public timeFormaterJsonToString(reqJson: any, format: any) {
        let time: any = `${reqJson.hour}-${reqJson.minute}`
        return moment(time, 'HH:mm').format(format);
    }
    public timeFormaterStringToJson(time: any) {
        var reqJson: any = {}
        reqJson.hour = parseInt(moment(time, 'HH:mm:ss').format('HH'));
        reqJson.minute = parseInt(moment(time, 'HH:mm:ss').format('mm'));
        // reqJson.seconds = parseInt(moment(time, 'HH:mm:ss').format('ss'));
        return reqJson;
    }
    public orderStatus(data: any) {
        switch (this.localData.getItem(constant.localStorage.module_id)) {
            case constant.moduleId.ekart:
                return data;
            case constant.moduleId.foodKart:
            case constant.moduleId.tableOrdering:
                if (data == constant.orderStatusName[3]) {
                    return 'Cooking';
                } else if (data == constant.orderStatusName[2]) {
                    return "Accept";
                } else {
                    return data;
                }
            case constant.moduleId.service:
                if (data == constant.orderStatusName[2]) {
                    return 'Accept';
                } else if (data == constant.orderStatusName[4]) {
                    return "Cancelled";
                } else {
                    return data;
                }
        }
    }
    public deliveryType(data: any) {
        return constant.DeliveryType[this.localData.getItem(constant?.localStorage?.module_id)][data]
    }

    public moduleWiseDeliveryType(data: any) {
        switch (data) {
            case constant.moduleId.ekart:
            case constant.moduleId.foodKart:
            case constant.moduleId.tableOrdering:
                data = [{ id: 1, name: "Takeaway" }, { id: 2, name: "Delivery" }];
                break;
            case constant.moduleId.service:
                data = [{ id: 1, name: "Drop in" }, { id: 2, name: "Call out service" }];
                break;
        }
        return data;
    }
    public add(val: any, val2: any) {
        return Number(Number(val) + Number(val2)).toFixed(2)
    }
    public moduleWiseOrderStatus(data: any) {
        switch (this.localData.getItem(constant.localStorage.module_id)) {
            case constant.moduleId.ekart:
                data = [{ order_status_name: 'Pending', order_status_id: 1 }, { order_status_name: 'Received', order_status_id: 2 }, { order_status_name: 'Processing', order_status_id: 3 },
                { order_status_name: 'Unserviceable', order_status_id: 4 }, { order_status_name: 'Ready', order_status_id: 5 }, { order_status_name: 'Completed', order_status_id: 6 }];
                break;
            case constant.moduleId.foodKart:
                data = [{ order_status_name: 'Pending', order_status_id: 1 }, { order_status_name: 'Accept', order_status_id: 2 }, { order_status_name: 'Cooking', order_status_id: 3 },
                { order_status_name: 'Unserviceable', order_status_id: 4 }, { order_status_name: 'Ready', order_status_id: 5 }, { order_status_name: 'Completed', order_status_id: 6 }];
                break;
            case constant.moduleId.service:
                data = [{ order_status_name: 'Pending', order_status_id: 1 }, { order_status_name: 'Cancelled', order_status_id: 4 },];
                break;
            case constant.moduleId.tableOrdering:
                data = [{ order_status_name: 'Pending', order_status_id: 1 }, { order_status_name: 'Accept', order_status_id: 2 }, { order_status_name: 'Cooking', order_status_id: 3 },
                { order_status_name: 'Unserviceable', order_status_id: 4 }, { order_status_name: 'Ready', order_status_id: 5 }, { order_status_name: 'Completed', order_status_id: 6 }];
                break;
        }
        return data;
    }
}

let commonLogics = new CommonLogicService();
export default commonLogics;
